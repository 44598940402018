import React from "react"
import PropTypes from "prop-types"
import AktuellesPreview from "./aktuelles-preview"
import RichText from "./rich-text"

const PageContent = ({ content }) => {
  return getContentComponent(content);
};

PageContent.propTypes = {
  content: PropTypes.object
}

PageContent.propDefaults = {
}

function getContentComponent({ _type, _rawContent }) {
  switch (_type) {
    case 'newsPreview':
      return <AktuellesPreview />
    case 'pageContent':
      return <RichText blocks={_rawContent} />
    default:
      return <h1>Default</h1>
  }
}

export default PageContent
