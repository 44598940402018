import React from "react"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Hero from "../components/hero"
import PageContent from "../components/page-content"

const DefaultTemplate = ({data}) => {
  const page = data.page;
  const { content, title, image } = page;
  const hero = content.find(pageContent => pageContent._type === 'hero');
  const contentWithoutHero = content.filter(pageContent => pageContent._type !== 'hero');
  return (
    <Layout>
      <Seo title={title} />
      <Banner image={image}>
        {
          hero ?
            <Hero {...hero}/> :
            <Container>
              <h1>{title}</h1>
            </Container>
        }
      </Banner>
      <Container>
        {
          contentWithoutHero.map((content, i) => <PageContent key={`content-${i}`} content={content} />)
        }
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: sanityPage(slug: {current: {eq: $slug}}) {
      title
      image {
        alt
        asset {
          url
        }
      }
      content {
        ... on SanityHero {
          _type
          cta {
            title
            url {
              href
              blank
            }
          }
          title
          subtitle
        }
        ... on SanityPagePreview {
          _key
          _type
        }
        ... on SanityPageContent {
          _key
          _type
          _rawContent(resolveReferences: {maxDepth: 20})
        }
        ... on SanityOpeningTimes {
          _key
          _type
        }
        ... on SanityNewsPreview {
          _key
          _type
        }
        ... on SanityInternalLink {
          _key
          _type
        }
        ... on SanityGallery {
          _key
          _type
        }
      }
    }
  }
`

export default DefaultTemplate
